import { useStable } from '@/hooks/useStable';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneCheckout } from 'base/components/Checkout';
import Tracking from 'base/components/TrackingComponent';
import getConfig from 'modules/config';

export const MabraStandaloneCheckout: typeof StandaloneCheckout = (props) => {
  const { closeTime } = getConfig('payments') ?? {};

  const paymentStable = useStable({
    closed: {
      time: closeTime,
      copy: 'Anmälan är nu stängd, håll utkik i våra kanaler för nästa tillfälle.',
    },
    options: {
      $form: {
        className: 'cts-impression-item',
        ...withTrackingAttributes({
          object: 'signup_box',
          creative: 'signup_box',
          objectName: 'klarna_payment',
          name: 'klarna_payment',
          objectExtraInfo: [{ campaign: 'mabra_course_sep24' }],
          label: [{ campaign: 'mabra_course_sep24' }],
        }),
      },
    },
    props,
  });

  return (
    <Tracking>
      <StandaloneCheckout {...paymentStable} />
    </Tracking>
  );
};
