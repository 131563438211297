import { tw } from '@/utils/tw';
import BaseCheckoutFooterTheme from 'base/components/CheckoutFooter/theme';

const CheckoutFooter = tw.theme({
  extend: BaseCheckoutFooterTheme,
  slots: {
    base: `relative flex flex-col items-center overflow-hidden bg-yellow-sea-100 p-8`,
    title: `z-10 mb-8 text-headline-md`,
    background: `absolute bottom-0 right-0 z-0 w-full text-yellow-sea-400`,
    content: `z-10 flex flex-col items-center rounded-xl bg-white px-8 py-4 [&>div]:m-2 [&>div]:flex [&>div]:flex-row [&>div]:items-center [&>div]:self-start [&>div]:text-headline-sm data-[last=true]:[&>div]:flex-col data-[last=true]:[&>div]:items-center data-[last=true]:[&>div]:self-center data-[last=true]:[&>div]:text-headline-2xs [&_img]:h-24 [&_img]:w-28 [&_svg]:mr-1 [&_svg]:text-cherry-blossom-300`,
    paymentGatewayLogo: `z-10 mt-1.5 flex flex-row items-center text-headline-2xs [&_img]:h-12 [&_img]:w-24`,
  },
});

export default CheckoutFooter;
