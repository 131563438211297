import { tw } from '@/utils/tw';
import BaseCheckoutHeaderTheme from 'base/components/CheckoutHeader/theme';

const CheckoutHeader = tw.theme({
  extend: BaseCheckoutHeaderTheme,
  slots: {
    base: `relative overflow-hidden bg-yellow-sea-100 px-8 py-10 md:px-24 lg:px-74`,
    background: `absolute -top-52 left-0 w-full text-yellow-sea-400`,
    content: `flex flex-col items-center`,
    contentImage: `flex h-16 w-20`,
    contentTitle: `text-headline-xl`,
    contentDescription: `mt-4 text-center text-body-md`,
  },
});

export default CheckoutHeader;
